import GraphicDesignData from "./GraphicDesignData";
export default function GraphicDesign() {
  const GraphicDesignPortfolioData = GraphicDesignData();
  return (
    <div>
      <div className="row">
        {GraphicDesignPortfolioData.map((graphicDesign, index) => (
          <div className={`margin-b20 content ${graphicDesign.col}`} key={index}>
            <a href={graphicDesign.image}>
              <div className="content-overlay"></div>
              <img
                className="w-100 box-shadow"
                src={graphicDesign.thumbnail}
                alt={graphicDesign.name}
              />
              <div className="content-details fadeIn-bottom">
                <h3 className="content-title">{graphicDesign.contentTitle}</h3>
                <p className="content-text">
                  {graphicDesign.contentText} <br />
                  {graphicDesign.LanguageUsed}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
