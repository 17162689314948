import FF20th from "../img/FF20th.jpg";
import xmasFF from "../img/xmasFF.jpg";
import Elizabeth from "../img/Elizabeth.jpg";
import RT from "../img/RT.jpg";
import FreeSpirits2 from "../img/FreeSpirits2.jpg";
import sfp from "../img/sfp.jpg";
import LearningCenterLOGO from "../img/LearningCenterLOGO.jpg";
import v29bet from "../img/v29bet.jpg";
import runLottoClub from "../img/runLottoClub.jpg";
import TCHapp from "../img/TCHapp.jpg";
import eee3 from "../img/eee3.gif";
import TCHp from "../img/TCHp.jpg";
import TCHsocial from "../img/TCHsocialAdd.jpg";
import QAreport from "../img/QAreport.jpg";
import Journal from "../img/JournalRSU.jpg";

//thumbnail
import FF20thTN from "../img/thumbnail/FF20thTN.jpg";
import xmasFFTN from "../img/thumbnail/xmasFFTN.jpg";
import ElizabethTN from "../img/thumbnail/ElizabethTN.jpg";
import RTTN from "../img/thumbnail/RTTN.jpg";
import FreeSpirits2TN from "../img/thumbnail/FreeSpirits2TN.jpg";
import sfpTN from "../img/thumbnail/sfpTN.jpg";
import LearningCenterLOGOTN from "../img/thumbnail/LearningCenterLOGOTN.jpg";
import v29betTN from "../img/thumbnail/v29betTN.jpg";
import runLottoClubTN from "../img/thumbnail/runLottoClubTN.jpg";
import TCHappTN from "../img/thumbnail/TCHappTN.jpg";
import eee3TN from "../img/thumbnail/eee3TN.gif";
import TCHpTN from "../img/thumbnail/TCHpTN.jpg";
import TCHsocialTN from "../img/thumbnail/TCHsocialAddTN.jpg";
import QAreportTN from "../img/thumbnail/QAreportTN.jpg";
import JournalTN from "../img/thumbnail/JournalRSUTN.jpg";

export default function GraphicDesignData() {
  const GraphicDesignPortfolioData = [
    {
      name: "Forever Florist Banner 20th years",
      image: FF20th,
      thumbnail: FF20thTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this banner for a florist company.",
      LanguageUsed:
        "Program used: Adobe Illustrator, Adobe lightroom, Adobe Photoshop",
      col: "col-lg-6",
    },
    {
      name: "Forever Florist Banner xmas",
      image: xmasFF,
      thumbnail: xmasFFTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this banner for a florist company.",
      LanguageUsed:
        "Program used: Adobe Illustrator, Adobe lightroom, Adobe Photoshop",
      col: "col-lg-6",
    },
    {
      name: "New Born frame",
      image: Elizabeth,
      thumbnail: ElizabethTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this newborn photoframe.",
      LanguageUsed:
        "Program used: Adobe Illustrator, Adobe lightroom, Adobe Photoshop",
      col: "col-lg-4",
    },
    {
      name: "TCH Application Design",
      image: TCHapp,
      thumbnail: TCHappTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this application for a private hospital.",
      LanguageUsed: "Program used: Adobe Illustrator",
      col: "col-lg-8",
    },
    {
      name: "River Terrace Logo design",
      image: RT,
      thumbnail: RTTN,
      contentTitle: "",
      contentText: "Graphic Desig",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },
    {
      name: "Free Spirits Logo design",
      image: FreeSpirits2,
      thumbnail: FreeSpirits2TN,
      contentTitle: "",
      contentText: "Graphic Design",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },
    {
      name: "Send-Flower-Pattaya Logo Design",
      image: sfp,
      thumbnail: sfpTN,
      contentTitle: "",
      contentText: "Graphic Design",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },
    {
      name: "Learning Center Logo Design",
      image: LearningCenterLOGO,
      thumbnail: LearningCenterLOGOTN,
      contentTitle: "",
      contentText: "Graphic Design",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },
    {
      name: "V29Bet Logo Design",
      image: v29bet,
      thumbnail: v29betTN,
      contentTitle: "",
      contentText: "Graphic Design",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },
    {
      name: "RunLottoClub Logo Design",
      image: runLottoClub,
      thumbnail: runLottoClubTN,
      contentTitle: "",
      contentText: "Graphic Design",
      LanguageUsed: "Logo",
      col: "col-lg-2 col-6",
    },

    {
      name: "Forever Florist add design",
      image: eee3,
      thumbnail: eee3TN,
      contentTitle: "Graphic Design",
      contentText: "I designed this google ads for a florist company.",
      LanguageUsed: "Program used: Adobe Photoshop",
      col: "col-lg-2",
    },
    {
      name: "TCH prochure design",
      image: TCHp,
      thumbnail: TCHpTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this brochure for a private hospital.",
      LanguageUsed: "Program used: Adobe Photoshop",
      col: "col-lg-6",
    },
    {
      name: "TCH social media post",
      image: TCHsocial,
      thumbnail: TCHsocialTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this social media post for a private hospital.",
      LanguageUsed: "Program used: Adobe Photoshop, Illustrtor",
      col: "col-lg-4",
    },
    {
      name: "QA report cover",
      image: QAreport,
      thumbnail: QAreportTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this cover for a University",
      LanguageUsed: "Program used: Illustrtor",
      col: "col-lg-6",
    },
    {
      name: "Journal cover",
      image: Journal,
      thumbnail: JournalTN,
      contentTitle: "Graphic Design",
      contentText: "I designed this journal cover for a University",
      LanguageUsed: "Program used: Illustrtor",
      col: "col-lg-6",
    },
  ];
  return GraphicDesignPortfolioData;
}
