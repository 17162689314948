import WebData from "./WebData";
export default function Websites() {
  const WebsitePortfolioData = WebData();
  return (
    <div>
      <p className="center">Websites, web pages, web components, Wordpress</p>
      <div className="row">
        {WebsitePortfolioData.map((website, index) => (
          <div className="col-lg-6 margin-b20 content" key={index}>
            <a href={website.Link} target="_blank" rel="noreferrer">
              <div className="content-overlay"></div>
              <img
                className="w-100 box-shadow"
                src={website.thumbnail}
                alt={website.name}
              />
              <div className="content-details fadeIn-bottom">
                <h3 className="content-title">{website.contentTitle}</h3>
                <p className="content-text">
                  {website.contentText} <br />
                  Language, Framework, and library used: {" "}{website.LanguageUsed}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
