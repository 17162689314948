import { Link } from "react-router-dom";
import NickyAbout from "../img/Nicky-About.jpg";
import NickyCV from "../img/NickyCV.jpg";

export default function About() {
  return (
    <div className="full-screen90 bg-gray">
      <div className="container">
        <div className="row section about">
          <div className="col-12">
            <h2>ABOUT ME</h2>
            <div className="line2"></div>
            <p className="padT10"></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 margin15-b">
            <div className="about-img box-shadow">
              <img src={NickyAbout} alt="About me" className="w-100" />
            </div>
          </div>
          <div className="col-lg-7 margin15-b">
            <div className="about-me">
              <h2>I'm Supapan Cheecharoen (Nicky)</h2>
              <h5>Front End Web Developer & Graphic Designer Based in the UK</h5>
              <br />
              <p>
                I'm a seasoned Front-End Web Developer with solid experience in
                Next.js and React. I specialize in crafting responsive and
                user-friendly websites, collaborating closely with UI/UX
                designers to bring designs to life.
              </p>
              <p>
                Originally trained as a graphic designer, I transitioned into
                front-end development in 2019 after discovering a passion for
                coding. With a background in design, I bring a unique
                perspective to my development work, ensuring both aesthetic
                appeal and functionality.
              </p>
              <p>
                In addition to development, I maintain and manage web content,
                implementing SEO best practices to enhance visibility and
                engagement.
              </p>
              <p>
                Committed to quality and continuous learning, I thrive in
                diverse, merit-based environments.
              </p>

              <br />

              {/* <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Birthday</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>14/06/1987</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Age</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>36 years old</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Residence</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>United Kingdom </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Email</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>nicky_hey@hotmail.com</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Line ID</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>nickycheecharoen</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 dark-gray">
                      <p>
                        <b>Phone</b>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>075 0863 9665</p>
                    </div>
                  </div>
                </div>
              </div>
              <br /> */}
              <div className="row margin40-b">
                <div className="col-12">
                  <Link
                    to={NickyCV}
                    download="NickyCV.jpg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="btn1">Download CV</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
