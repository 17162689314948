import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

export default function Contact() {
  // const SERVICE_ID = "service_bo8sphj"
  // const TEMPLATE_ID = "template_l64088r"
  // const PUBLIC_KEY = "I0f3HWdLW8iNmu4CG"

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_bo8sphj",
          "template_l64088r",
          form.current,
          "I0f3HWdLW8iNmu4CG"
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              icon: "success",
              title: "Message Sent Successfully",
            });
          },
          (error) => {
            console.log(error.text);
            Swal.fire({
              icon: "error",
              title: "Failed to Send Message",
              text: "Oops, something went wrong!",
            });
          }
        );
    }
  }
  return (
    <div className="bg-gray">
      <div className="container">
        <div className="row section full-screen90">
          <div className="col-lg-4 margin15-b">
            <h2>CONTACT ME</h2>
            <div className="line2"></div>
            <p className="padT10">
              A Graphic Designer & Front End developer <br />
              Let's work together.
            </p>
            <div className="contact-me margin15-b">
              <div className="icon">
                <i className="fi fi-rs-envelopes"></i>
              </div>
              <div className="text">nicky_hey@hotmail.com</div>
            </div>
            <div className="contact-me margin40-b">
              <div className="icon">
                <i className="fi fi-rs-phone-call"></i>
              </div>
              <div className="text">075 0863 9665</div>
            </div>
          </div>
          <div className="col-lg-8 margin15-b">
            <div className="contact-form box-shadow">
              <h4>Send Me Message</h4>
              <form
                ref={form}
                onSubmit={sendEmail}
                className="row"
                id="contact-form"
              >
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      name="name"
                      // id="name"
                      placeholder="Name *"
                      className="form-control effect-line"
                      type="text"
                      required
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      name="email"
                      // id="email"
                      placeholder="Email *"
                      className="form-control effect-line"
                      type="email"
                      required
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      name="subject"
                      // id="subject"
                      placeholder="Subject *"
                      className="form-control effect-line"
                      type="text"
                      required
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      // id="message"
                      placeholder="Message *"
                      rows="3"
                      className="form-control effect-line"
                    ></textarea>
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="send">
                    <button className="btn1" type="submit" value="Send">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
