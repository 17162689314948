import { useState } from "react";
import { Link } from "react-router-dom";
import Websites from "./Websites";
import GraphicDesign from "./GraphicDesign";
import FF20th from "../img/FF20th.jpg";
import xmasFF from "../img/xmasFF.jpg";
import Elizabeth from "../img/Elizabeth.jpg";
import TCHapp from "../img/TCHapp.jpg";
import eee3 from "../img/eee3.gif";
import TCHp from "../img/TCHp.jpg";
import Others from "./Others";

// thumbnail
import TCHwebsiteTN from "../img/thumbnail/TCHwebsiteTN.jpg";
import FF20thTN from "../img/thumbnail/FF20thTN.jpg";
import xmasFFTN from "../img/thumbnail/xmasFFTN.jpg";
import ElizabethTN from "../img/thumbnail/ElizabethTN.jpg";
import stage03TN from "../img/thumbnail/stage03TN.gif";
import RTTN from "../img/thumbnail/RTTN.jpg";
import FreeSpirits2TN from "../img/thumbnail/FreeSpirits2TN.jpg";
import sfpTN from "../img/thumbnail/sfpTN.jpg";
import LearningCenterLOGOTN from "../img/thumbnail/LearningCenterLOGOTN.jpg";
import v29betTN from "../img/thumbnail/v29betTN.jpg";
import runLottoClubTN from "../img/thumbnail/runLottoClubTN.jpg";
import TCHappTN from "../img/thumbnail/TCHappTN.jpg";
import eee3TN from "../img/thumbnail/eee3TN.gif";
import TCHpTN from "../img/thumbnail/TCHpTN.jpg";

export default function Portfolio() {
  const [activeTab, setActiveTab] = useState("tab1");
  function handleTabClick(tabName) {
    console.log("Active Tab", tabName);
    setActiveTab(tabName);
  }

  return (
    <div className="full-screen90">
      <div className="container">
        <div className="row section about">
          <div className="col-12 center">
            <h2>PORTFOLIO</h2>
          </div>
        </div>
        <div className="tabs margin-tb40">
          <div className="tabbar">
            <button
              onClick={() => handleTabClick("tab1")}
              className={`tab1 tab ${activeTab === "tab1" ? "active" : ""}`}
            >
              All Categories
            </button>
            <button
              onClick={() => handleTabClick("tab2")}
              className={`tab2 tab ${activeTab === "tab2" ? "active" : ""}`}
            >
              Web
            </button>
            <button
              onClick={() => handleTabClick("tab3")}
              className={`tab3 tab ${activeTab === "tab3" ? "active" : ""}`}
            >
              Graphic Design
            </button>
            <button
              onClick={() => handleTabClick("tab4")}
              className={`tab4 tab ${activeTab === "tab4" ? "active" : ""}`}
            >
              Others
            </button>
          </div>
          <div className="tabpanel">
            {activeTab === "tab1" && (
              <div>
                <div className="row">
                  <div className="col-lg-6 margin-b20 content">
                    <a href="https://www.thonburi-chumphon.com" target="_blank" rel="noreferrer">
                      <div className="content-overlay"></div>
                      <img
                        className="w-100 box-shadow"
                        src={TCHwebsiteTN}
                        alt="TCH website"
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">Website</h3>
                        <p className="content-text">
                          I designed and coded this Next.js hospital
                          website.
                          <br/>
                          Languages, library, and Framework used: Next.js, javaScript, bootstrap, HTML, CSS                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12 margin-b20 content">
                        <a href={FF20th}>
                          <div className="content-overlay"></div>
                          <img
                            className="w-100 box-shadow"
                            src={FF20thTN}
                            alt="Forever Florist Banner"
                          />
                          <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">Graphic design</h3>
                            <p className="content-text">
                              I designed this banner for a florist company.
                              <br />
                              Program used: Adobe Illustrator, Adobe lightroom,
                              Adobe Photoshop
                            </p>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 margin-b20 content">
                        <a href={xmasFF}>
                          <div className="content-overlay"></div>
                          <img
                            className="w-100 box-shadow"
                            src={xmasFFTN}
                            alt="Forever Florist x-mas Banner"
                          />
                          <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">Graphic Design</h3>
                            <p className="content-text">
                              I designed this banner for a florist company.
                              <br />
                              Program used: Adobe Illustrator, Adobe lightroom,
                              Adobe Photoshop
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 margin-b20 content">
                    <a href={Elizabeth}>
                      <div className="content-overlay"></div>
                      <img
                        className="w-100 box-shadow"
                        src={ElizabethTN}
                        alt="New Born frame"
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">Graphic Design</h3>
                        <p className="content-text">
                          I designed this newborn photoframe.
                          <br />
                          Program used: Adobe Illustrator, Adobe lightroom,
                          Adobe Photoshop
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-8 ">
                    <div className="row">
                      <div className="col-12 margin-b20 content">
                        <Link to="/portfolio/tracking-page">
                          <div className="content-overlay"></div>
                          <img
                            className="w-100 box-shadow"
                            src={stage03TN}
                            alt="web component design"
                          />
                          <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">Web Component</h3>
                            <p className="content-text">
                              I designed and coded this web component for a
                              florist company .<br />
                              Language and Program used: HTML, CSS, javaScript,
                              Adobe Photoshop
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-12 ">
                        <div className="row">
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={RTTN}
                              alt="River Terrace Logo design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={FreeSpirits2TN}
                              alt="Free Spirits Logo design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={sfpTN}
                              alt="Send-Flower-Pattaya Logo Design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={LearningCenterLOGOTN}
                              alt="Learning Center Logo Design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={v29betTN}
                              alt="V29Bet Logo Design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 margin-b20 content">
                            <div className="content-overlay"></div>
                            <img
                              className="w-100 box-shadow"
                              src={runLottoClubTN}
                              alt="Run Lotto Club Logo Design"
                            />
                            <div className="content-details fadeIn-bottom">
                              <p className="content-text">
                                Graphic Design <br />
                                Logo
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 margin-b20 content">
                    <a href={TCHapp}>
                      <div className="content-overlay"></div>
                      <img
                        className="w-100 box-shadow"
                        src={TCHappTN}
                        alt="TCH Application Design"
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">Graphic Design</h3>
                        <p className="content-text">
                          I designed this application for a private hospital.
                          <br />
                          Program used: Adobe Illustrator
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-2 margin-b20 content">
                    <a href={eee3}>
                      <div className="content-overlay"></div>
                      <img
                        className="w-100 box-shadow"
                        src={eee3TN}
                        alt="Forever Florist add design"
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">Graphic Design</h3>
                        <p className="content-text">
                          I designed this google ads for a florist company.
                          <br />
                          Program used: Adobe Photoshop
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-5 margin-b20 content">
                    <a href={TCHp}>
                      <div className="content-overlay"></div>
                      <img
                        className="w-100 box-shadow"
                        src={TCHpTN}
                        alt="TCH prochure design"
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">Graphic Design</h3>
                        <p className="content-text">
                          I designed this brochure for a private hospital.
                          <br />
                          Program used: Adobe Illustrator
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "tab2" && <div>
              <Websites/>
              </div>}
            {activeTab === "tab3" && (
              <GraphicDesign/>
            )}
            {activeTab === "tab4" && <div>
              <Others/>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
