import OtherData from "./OtherData";
export default function Others() {
  const otherPortfolioData = OtherData();
  return (
    <div>
      <div className="row">
        {otherPortfolioData.map((otherDesign, index) => (
          <div className={`margin-b20 content ${otherDesign.col}`} key={index}>
            <a href={otherDesign.image}>
              <div className="content-overlay"></div>
              <img
                className="w-100 box-shadow"
                src={otherDesign.thumbnail}
                alt={otherDesign.name}
              />
              <div className="content-details fadeIn-bottom">
                <h3 className="content-title">{otherDesign.contentTitle}</h3>
                <p className="content-text">
                  {otherDesign.contentText} <br />
                  {otherDesign.LanguageUsed}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
