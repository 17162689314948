import RSUresearch from "../img/RSUresearch.jpg";
import QA from "../img/QA.jpg";
import research from "../img/research.jpg";
import sendFlowerPattaya from "../img/sendFlowerPattaya.jpg";
import TCHwebsite from "../img/TCHwebsite.jpg";
import conference from "../img/conference.jpg";
import flowerDeliveryReview from "../img/flowerDeliveryReview.jpg";
import cec from "../img/cec.jpg";
import stage03 from "../img/stage03.gif";

//thumbnail
import RSUresearchTN from "../img/thumbnail/RSUresearchTN.jpg";
import QATN from "../img/thumbnail/QATN.jpg";
import researchTN from "../img/thumbnail/researchTN.jpg";
import sendFlowerPattayaTN from "../img/thumbnail/sendFlowerPattayaTN.jpg";
import TCHwebsiteTN from "../img/thumbnail/TCHwebsiteTN.jpg";
import conferenceTN from "../img/thumbnail/conferenceTN.jpg";
import flowerDeliveryReviewTN from "../img/thumbnail/flowerDeliveryReviewTN.jpg";
import cecTN from "../img/thumbnail/cecTN.jpg";
import stage03TN from "../img/thumbnail/stage03TN.gif";

export default function WebData() {
  const WebsitePortfolioData = [
    {
      name: "TCH website",
      image: TCHwebsite,
      thumbnail: TCHwebsiteTN,
      contentTitle: "Website",
      contentText:
        "I designed, coded, and maintain this responsive hospital website.",
      LanguageUsed: "Next.js, React, javaScript, bootstrap, HTML, CSS",
      Link: "https://thonburi-chumphon.com",
    },
    {
      name: "CEC website",
      image: cec,
      thumbnail: cecTN,
      contentTitle: "Website",
      contentText: "I designed and coded this React website",
      LanguageUsed: "React, Bootstrap, CSS",
      Link: "https://www.chumvej-elderly-care.com/",
    },
    {
      name: "RSU research website",
      image: RSUresearch,
      thumbnail: RSUresearchTN,
      contentTitle: "Website",
      contentText: "I designed and coded this responsive University website.",
      LanguageUsed: "HTML, CSS, bootstrap, javaScript, jQuery.",
      Link: "https://rsu-kappa.vercel.app/",
    },
    {
      name: "QA Rangsit University Website",
      image: QA,
      thumbnail: QATN,
      contentTitle: "Website",
      contentText: "I designed and coded this responsive University website.",
      LanguageUsed: "HTML, CSS, bootstrap, javaScript, jQuery.",
      Link: "https://qa-rsu.vercel.app/",
    },
    {
      name: "Research Rangsit University Website",
      image: research,
      thumbnail: researchTN,
      contentTitle: "Website",
      contentText: "I designed and coded this responsive University website.",
      LanguageUsed: "HTML, CSS, bootstrap, javaScript, jQuery.",
      Link: "https://research-rsu.vercel.app/",
    },
    {
      name: "Send-flowers-pattaya website",
      image: sendFlowerPattaya,
      thumbnail: sendFlowerPattayaTN,
      contentTitle: "Website",
      contentText:
        "I designed and builded this responsive wordpress website for a florist company.",
      LanguageUsed: "wordpress elementor, WooCommerce.",
      Link: "https://send-flowers-pattaya.com/",
    },
    {
      name: "conference Rangsit University Website",
      image: conference,
      thumbnail: conferenceTN,
      contentTitle: "Website",
      contentText: "I designed and coded this responsive University conference website.",
      LanguageUsed: "HTML, CSS, bootstrap, javaScript.",
      Link: "https://conference-rsu.vercel.app/",
    },
    {
      name: "Track my order page",
      image: stage03,
      thumbnail: stage03TN,
      contentTitle: "Web Component",
      contentText: "I designed and coded this track-my-order web component",
      LanguageUsed: "HTML, CSS, JavaScript, Photoshop(gif)",
      Link: "portfolio/tracking-page",
    },
    {
      name: "Flower Delivery Review website",
      image: flowerDeliveryReview,
      thumbnail: flowerDeliveryReviewTN,
      contentTitle: "Website",
      contentText: "I designed and coded this responsive flower review website.",
      LanguageUsed: "HTML, CSS, bootstrap.",
      Link: "https://flower-delivery-review.vercel.app/",
    },
  ];
  return WebsitePortfolioData;
}
