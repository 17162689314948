import diyClock from "../img/diyClock.jpg";
import CoffeeLight from "../img/CoffeeLight.jpg";
import lionPaint from "../img/lionPaint.jpg";
import dogPaint from "../img/dogPaint.jpg";
import slippers from "../img/slippers.jpg";

//thumbnail
import diyClockTN from "../img/thumbnail/diyClockTN.jpg";
import CoffeeLightTN from "../img/thumbnail/CoffeeLightTN.jpg";
import lionPaintTN from "../img/thumbnail/lionPaintTN.jpg";
import dogPaintTN from "../img/thumbnail/dogPaintTN.jpg";
import slippersTN from "../img/thumbnail/slippersTN.png";

export default function OtherData() {
  const otherPortfolioData = [
    {
      name: "DIY Clock Wall Art",
      image: diyClock,
      thumbnail: diyClockTN,
      contentTitle: "Others",
      contentText: "I designed and made this clock wall art.",
      LanguageUsed: "",
      col: "col-lg-6",
    },
    {
      name: "DIY Light",
      image: CoffeeLight,
      thumbnail: CoffeeLightTN,
      contentTitle: "Others",
      contentText:
        "I designed and made this lab tube light for the Coffee Lab cafe.",
      LanguageUsed: "",
      col: "col-lg-3",
    },
    {
      name: "Lion Painting",
      image: lionPaint,
      thumbnail: lionPaintTN,
      contentTitle: "Others",
      contentText: "I painted this lion picture.",
      LanguageUsed: "",
      col: "col-lg-3",
    },
    {
      name: "Dog Painting",
      image: dogPaint,
      thumbnail: dogPaintTN,
      contentTitle: "Others",
      contentText: "I painted this dog picture.",
      LanguageUsed: "",
      col: "col-lg-6",
    },
    {
      name: "Slippers",
      image: slippers,
      thumbnail: slippersTN,
      contentTitle: "Others",
      contentText: "I made this slipper",
      LanguageUsed: "",
      col: "col-lg-6",
    },
  ];
  return otherPortfolioData;
}
