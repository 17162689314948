import { Link, NavLink } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";
import NickyCV from "../img/NickyCV.jpg";
import SupapanRecomendation from "../img/SupapanRecomendation.pdf";

export default function Header() {
  const [show, setShow] = useState(true);
  return (
    <header>
      <div className="socialTab">{}</div>
      <div className="topArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-7">
              <Link to="/" className="logo-item">
                <div className="logo">S</div>
                <div className="logo-txt">
                  <div>
                    <h3>Supapan</h3>
                  </div>
                  <div>
                    <span>Cheecharoen</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="nav-area col-lg-6">
              <nav className="mainnav">
                <ul>
                  <li id="home" className="color1">
                    <NavLink
                      to="/"
                      className="nav-item"
                      activeclassname="active"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li id="about" className="color2">
                    <NavLink
                      to="/about-me"
                      className="nav-item"
                      activeclassname="active"
                    >
                      About
                    </NavLink>
                  </li>
                  <li id="portfolio" className="color3">
                    <NavLink
                      to="/portfolio"
                      className="nav-item"
                      activeclassname="active"
                    >
                      Portfolio
                    </NavLink>
                  </li>
                  <li id="cv" className="color4">
                    <a
                      href={NickyCV}
                      className="nav-item"
                      activeclassname="active"
                    >
                      CV
                    </a>
                  </li>
                  <li id="Reference" className="color5">
                    <a
                      href={SupapanRecomendation}
                      className="nav-item"
                      activeclassname="active"
                    >
                      Reference
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="contact-area col-lg-3 col-5">
              <div className="contact-btn">
                <Link to="/contact">Contact</Link>
              </div>

              <div onClick={() => setShow(!show)} className="mobile-menu">
                {
                  <div className="mobile-menu-button">
                    <FiMenu />
                  </div>
                }
              </div>

              {/* Mobile Menu End */}
            </div>
            {!show && (
              <div className="mobileDropdown">
                <div className="collapse-menu-container">
                  <Link to="/">
                    <button className="mobileMenuGo">Home</button>
                  </Link>
                  <Link to="/about-me">
                    <button className="mobileMenuGo">About</button>
                  </Link>
                  <Link to="/portfolio">
                    <button className="mobileMenuGo">Portfolio</button>
                  </Link>
                  <a href={NickyCV}>
                    <button className="mobileMenuGo">CV</button>
                  </a>
                  <a href={SupapanRecomendation}>
                    <button className="mobileMenuGo">Reference</button>
                  </a>
                  <Link to="/contact">
                    <button className="mobileMenuGo">Contact</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
