import stage01 from "../img/stage01.gif"
import stage02 from "../img/stage02.gif"
import stage03 from "../img/stage03.gif"
import stage04 from "../img/stage04.gif"
import stage05 from "../img/stage05.gif"
import stage06 from "../img/stage06.gif"

export default function TrackingPage() {
  return (
    <div className="full-screen90 margin-tb40">
      <div className="container">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={stage01} className="d-block w-100" alt="Tracking stage1"/>
            </div>
            <div className="carousel-item">
              <img src={stage02} className="d-block w-100" alt="Tracking stage2"/>
            </div>
            <div className="carousel-item">
              <img src={stage03} className="d-block w-100" alt="Tracking stage3"/>
            </div>
            <div className="carousel-item">
              <img src={stage04} className="d-block w-100" alt="Tracking stage4"/>
            </div>
            <div className="carousel-item">
              <img src={stage05} className="d-block w-100" alt="Tracking stage5"/>
            </div>
            <div className="carousel-item">
              <img src={stage06} className="d-block w-100" alt="Tracking error"/>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}
