import { Link } from "react-router-dom";
import MyPhotoCircle from "../img/Nicky-circle.png";
import NickyCV from "../img/NickyCV.jpg";

export default function Home() {
  return (
    <main className="full-screen90">
      <div className="container">
        <div className="row banner-area">
          <div className="col-lg-6">
            <div>
              <span className="intro1">Welcome to My Portfolio</span>
              <span className="intro2">Hi, I'm Supapan Cheecharoen</span>
              <p>
                I am a Front-End Web Developer & Graphic Designer based in the UK, passionate about
                coding and transforming concepts into vibrant web experiences.
                With a keen eye for detail, I approach each project with a blend
                of creativity and precision. I take pride in my neat and
                punctual work ethic, ensuring that every aspect of a website is
                meticulously crafted to perfection.
              </p>
              <div className="banner-btn-area">
                <Link to="/contact">
                  <div className="btn1 margin-r-20">Hire Me</div>
                </Link>
                <a
                  href={NickyCV}
                  download="NickyCV.jpg"
                  target="_blank"
                  rel="noreferrer"
                  className="btn2"
                >
                  Download CV
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 banner-profile-pic-area">
            <img
              className="my-photo-circle"
              src={MyPhotoCircle}
              alt="Supapan"
            />
            <div className="profile-circle"></div>
          </div>
        </div>
      </div>
    </main>
  );
}
